import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import {
  Home,
  StaySafe,
  PrivacyPolicy,
  TermsNConditions,
  IntellectualProperty,
  PartnerPrivacyPolicy,
  PartnerTermsNConditions,
  FAQs,
  ContactUs,
  Error,
} from "./pages/pages.js";
import {
  Preloader,
  Footer,
  EmailActionHandlers,
} from "./components/components.js";

const routeVariants = {
  initial: {
    y: "-100vh",
  },
  final: {
    y: "0vh",
    transition: {
      type: "spring",
      bounce: 0,
    },
  },
};

function LocationProvider({ children }) {
  return <AnimatePresence>{children}</AnimatePresence>;
}

function RoutesWithAnimation() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.key}>
      <Route path="/" element={<Home />} />
      <Route
        path="stay-safe"
        element={
          <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
            className="home component"
          >
            <StaySafe />
          </motion.div>
        }
      />
      <Route
        path="privacy-policy"
        element={
          <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
            className="home component"
          >
            <PrivacyPolicy />
          </motion.div>
        }
      />
      <Route
        path="terms-and-conditions"
        element={
          <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
            className="home component"
          >
            <TermsNConditions />
          </motion.div>
        }
      />
      <Route
        path="intellectual-property"
        element={
          <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
            className="home component"
          >
            <IntellectualProperty />
          </motion.div>
        }
      />
      <Route
        path="partner/privacy-policy"
        element={
          <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
            className="home component"
          >
            <PartnerPrivacyPolicy />
          </motion.div>
        }
      />
      <Route
        path="partner/terms-and-conditions"
        element={
          <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
            className="home component"
          >
            <PartnerTermsNConditions />
          </motion.div>
        }
      />

      <Route
        path="faqs"
        element={
          <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
            className="home component"
          >
            <FAQs />
          </motion.div>
        }
      />
      <Route
        path="contact-us"
        element={
          <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
            className="home component"
          >
            <ContactUs />
          </motion.div>
        }
      />
      <Route
        path="auth/action"
        element={
          <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
            className="home component"
          >
            <EmailActionHandlers />
          </motion.div>
        }
      />
      <Route
        path="*"
        element={
          <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
            className="home component"
          >
            <Error />
          </motion.div>
        }
      />
    </Routes>
  );
}

function App() {
  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const initialPath = window.location.pathname;
    if (initialPath !== "/") {
      setShowPreloader(false);
    }
  }, []);

  return (
    <>
      {showPreloader && <Preloader />}

      <BrowserRouter>
        <LocationProvider>
          <RoutesWithAnimation />
        </LocationProvider>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
