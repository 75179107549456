import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { NavigationBar } from "../components/components";
import "../styles/common.css"; // Import CSS file for styling

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
    },
  },
};

const TermsNConditions = () => {
  const [navHeight, setNavHeight] = useState(0); // State to store navigation bar height

  // Measure navigation bar height
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (navbar) {
      const height = navbar.offsetHeight;
      setNavHeight(height);
    }
  }, []);

  return (
    <motion.div variants={childVariants} initial="initial" animate="final">
      <NavigationBar color={false} />

      <div className="common" style={{ paddingTop: navHeight }}>
        <h1>One Date Terms and Conditions</h1>
        <caption>Effective Date: 15 June 2024</caption>
        <p>
          Welcome to One Date ("One Date," "we," "us," or "our"). These Terms
          and Conditions ("Terms") govern your use of our mobile application
          (the "App"). By accessing or using the App, you agree to be bound by
          these Terms. If you disagree with any part of these Terms, then you
          may not access or use the App.
        </p>
        <h2>1. Eligibility</h2>
        <p>
          You must be at least 18 years old to use the App. By using the App,
          you represent and warrant that you are at least 18 years old and of
          legal capacity to enter into this agreement.
        </p>
        <h2>2. User Accounts</h2>
        <p>
          You may create an account with the App ("Account"). You are
          responsible for maintaining the confidentiality of your Account
          information, including your login credentials, and for all activity
          that occurs under your Account. You agree to notify One Date
          immediately of any unauthorized use of your Account or any other
          security breach.
        </p>
        <h2>3. User Content</h2>
        <p>
          You are responsible for all content that you upload, post, or
          otherwise transmit through the App ("User Content"). You retain all
          ownership rights to your User Content. However, by submitting User
          Content, you grant One Date a non-exclusive, royalty-free, worldwide
          license to use, modify, reproduce, distribute, display, and translate
          your User Content in connection with the App and our services.
        </p>
        <h2>4. In-App Subscription</h2>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>Subscription:</span>{" "}
            Subscriptions automatically renew unless canceled at least 24 hours
            before the end of the current period. Manage your subscriptions and
            auto-renewal settings in your App Store / Google Play Store account
            settings.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>Charges:</span> The
            subscription fee will be charged to your iTunes / Google Play
            account at confirmation of purchase. Price may vary by location and
            currency
          </li>
        </ul>
        <h2>5. Prohibited Activities</h2>
        <caption>
          You agree not to use the App for any unlawful purpose or in any manner
          that violates these Terms. You agree not to use the App to:
        </caption>
        <ul>
          <li>Harass, abuse, or threaten others.</li>
          <li>
            Create or share content that is illegal, obscene, defamatory,
            hateful, or promotes violence.
          </li>
          <li>
            Create, share, or distribute content involving child sexual abuse
            and exploitation (CSAE), including any explicit or implicit
            materials depicting minors inappropriately.
          </li>
          <li>Impersonate any person or entity.</li>
          <li>Interfere with or disrupt the App or its servers.</li>
          <li>Violate the intellectual property rights of others.</li>
          <li>
            Use the App for any commercial purpose without our express written
            consent.
          </li>
        </ul>
        <h2>6. Disclaimers</h2>
        <p>
          The App is provided "as is" and without warranties of any kind,
          express or implied. One Date disclaims all warranties, including, but
          not limited to, warranties of merchantability, fitness for a
          particular purpose, and non-infringement. One Date does not warrant
          that the App will be uninterrupted, secure, or error-free.
        </p>
        <h2>7. Limitation of Liability</h2>
        <p>
          One Date shall not be liable for any damages arising out of your use
          of the App, including, but not limited to, direct, indirect,
          incidental, consequential, or punitive damages.
        </p>
        <h2>8. Termination</h2>
        <p>
          We may terminate or suspend your Account at any time, for any reason,
          without notice. You may terminate your Account at any time. Upon
          termination of your Account, your right to use the App will cease
          immediately.
        </p>
        <h2>9. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of Singapore, without regard to its conflict of law provisions.
        </p>
        <h2>10. Entire Agreement</h2>
        <p>
          These Terms constitute the entire agreement between you and One Date
          regarding your use of the App.
        </p>
        <h2>11. Changes to the Terms</h2>
        <p>
          We may update these Terms at any time. You are advised to review the
          Terms periodically for any changes. Your continued use of the App
          after the revised Terms are posted constitutes your acceptance of the
          revised Terms.
        </p>
        <h2>12. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at{" "}
          <a style={{ color: "inherit" }} href="mailto:terms@onedate.app">
            terms@onedate.app
          </a>
          .
        </p>
      </div>
    </motion.div>
  );
};

export default TermsNConditions;
