import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { NavigationBar } from "../components/components";
import "../styles/common.css"; // Import CSS file for styling

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
    },
  },
};

const PartnerPrivacyPolicy = () => {
  const [navHeight, setNavHeight] = useState(0); // State to store navigation bar height

  // Measure navigation bar height
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (navbar) {
      const height = navbar.offsetHeight;
      setNavHeight(height);
    }
  }, []);

  return (
    <motion.div variants={childVariants} initial="initial" animate="final">
      <NavigationBar color={false} />

      <div className="common" style={{ paddingTop: navHeight }}>
        <h1>One Date Partner Portal Privacy Policy</h1>
        <caption>Effective Date: 25 January 2025</caption>
        <p>
          One Date Partner Portal ("Partner Portal," "we," "us," or "our") is
          committed to protecting the privacy of our users ("you" or "your").
          This Privacy Policy explains what information we collect, how we use
          it, and your choices regarding this information. For account-related
          data, please refer to the{" "}
          <a
            style={{ color: "inherit" }}
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            One Date App Privacy Policy
          </a>
          .
        </p>
        <h2>Information We Collect:</h2>
        <caption>
          We collect the following information when you use the Partner Portal:
        </caption>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>Account Information:</span>{" "}
            When you create an account in the Partner Portal, we collect your
            email address, name, business details, and any additional
            information you provide during registration.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>Deal Submission Data:</span>{" "}
            We collect information about the deals you submit, including
            descriptions, images, pricing details, and other relevant data.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>Usage Data:</span> We collect
            data about your activity on the Partner Portal, such as the deals
            submitted, your interactions with the platform, and timestamps.
          </li>
        </ul>
        <h2>How We Use Your Information:</h2>
        <caption>
          We use the information we collect for the following purposes:
        </caption>
        <ul>
          <li>To create and manage your account on the Partner Portal</li>
          <li>To enable your account for use on the One Date App</li>
          <li>To process, display, and manage the deals you submit</li>
          <li>To improve and optimize the Partner Portal</li>
          <li>To comply with legal and regulatory requirements</li>
        </ul>
        <h2>Firebase and Your Information:</h2>
        <p>
          The Partner Portal uses Firebase, a service provided by Google, as our
          backend. Google collects and stores information in accordance with
          their own{" "}
          <a
            style={{ color: "inherit" }}
            href="https://firebase.google.com/support/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          . We recommend you review their policy for more details.
        </p>
        <h2>Sharing Your Information:</h2>
        <p>
          We may share your information with third-party service providers who
          help us operate the Partner Portal. These providers will handle your
          information in accordance with this Privacy Policy and any necessary
          agreements. <br /> <br /> We will not share your information with any
          third-party for marketing purposes without your consent.
        </p>
        <h2>Your Choices:</h2>
        <caption>You have choices regarding your information:</caption>
        <ul>
          <li>
            You can access and update your profile information at any time
            through the Partner Portal.
          </li>
          <li>
            You can request to delete your account. Note that this will also
            impact your ability to log in to the One Date App.
          </li>
        </ul>
        <h2>Security:</h2>
        <p>
          We take reasonable steps to protect your information from unauthorized
          access, disclosure, alteration, or destruction. However, no security
          measures are perfect, and we cannot guarantee the security of your
          information.
        </p>
        <h2>Children's Privacy:</h2>
        <p>
          The Partner Portal is not intended for use by children under the age
          of 18. We do not knowingly collect information from children under 18.
          If you are a parent or guardian and believe your child has provided us
          with information, please contact us.
        </p>
        <h2>Changes to this Privacy Policy:</h2>
        <p>
          We may update this Privacy Policy from time to time. We encourage you
          to periodically review this Privacy Policy for any changes.
        </p>
        <h2>Contact Us:</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at{" "}
          <a
            style={{ color: "inherit" }}
            href="mailto:privacypolicy@onedate.app"
          >
            privacy@onedate.app
          </a>
          .
        </p>
      </div>
    </motion.div>
  );
};

export default PartnerPrivacyPolicy;
