import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { NavigationBar } from "../components/components";
import "../styles/common.css"; // Import CSS file for styling

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
    },
  },
};

const PartnerTermsNConditions = () => {
  const [navHeight, setNavHeight] = useState(0); // State to store navigation bar height

  // Measure navigation bar height
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (navbar) {
      const height = navbar.offsetHeight;
      setNavHeight(height);
    }
  }, []);

  return (
    <motion.div variants={childVariants} initial="initial" animate="final">
      <NavigationBar color={false} />

      <div className="common" style={{ paddingTop: navHeight }}>
        <h1>One Date Partner Portal Terms and Conditions</h1>
        <caption>Effective Date: 25 January 2025</caption>
        <p>
          Welcome to the One Date Partner Portal ("Partner Portal," "we," "us,"
          or "our"). These Terms and Conditions ("Terms") govern your use of the
          Partner Portal. By accessing or using the Partner Portal, you agree to
          be bound by these Terms. If you disagree with any part of these Terms,
          then you may not access or use the Partner Portal.
        </p>
        <h2>1. Eligibility</h2>
        <p>
          You must be at least 18 years old to use the Partner Portal. By using
          the Partner Portal, you represent and warrant that you are at least 18
          years old and of legal capacity to enter into this agreement.
        </p>
        <h2>2. User Accounts</h2>
        <p>
          You may create an account on the Partner Portal ("Account"). This
          account can also be used to log in to the One Date App. You are
          responsible for maintaining the confidentiality of your account
          information, including your login credentials, and for all activity
          that occurs under your account. You agree to notify us immediately of
          any unauthorized use of your account or any other security breach. For
          more details regarding account-related terms, please refer to the{" "}
          <a
            style={{ color: "inherit" }}
            href="/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            One Date App Terms and Conditions
          </a>
          .
        </p>
        <h2>3. Partner Content</h2>
        <p>
          You are responsible for all content, including deal submissions, that
          you upload, post, or otherwise transmit through the Partner Portal
          ("Partner Content"). You retain all ownership rights to your Partner
          Content. However, by submitting Partner Content, you grant us a
          non-exclusive, royalty-free, worldwide license to use, modify,
          reproduce, distribute, display, and translate your Partner Content for
          purposes related to the Partner Portal and the One Date App.
        </p>
        <h2>4. Submissions and Transactions</h2>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>Deal Submissions:</span> By
            submitting deals, you represent and warrant that the information
            provided is accurate, lawful, and does not infringe on any
            third-party rights.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>Payment Terms:</span> If
            applicable, payments and charges related to deals or services
            provided through the Partner Portal must comply with any agreements
            between you and One Date.
          </li>
        </ul>
        <h2>5. Prohibited Activities</h2>
        <caption>
          You agree not to use the Partner Portal for any unlawful purpose or in
          any manner that violates these Terms. Specifically, you agree not to:
        </caption>
        <ul>
          <li>Submit false or misleading deals or information.</li>
          <li>Harass, abuse, or threaten others.</li>
          <li>
            Upload or share content that is illegal, obscene, defamatory,
            hateful, or promotes violence.
          </li>
          <li>
            Upload content that infringes on the intellectual property rights of
            others.
          </li>
          <li>Interfere with or disrupt the Partner Portal or its servers.</li>
          <li>
            Use the Partner Portal for any unauthorized commercial purpose
            without our express written consent.
          </li>
        </ul>
        <h2>6. Disclaimers</h2>
        <p>
          The Partner Portal is provided "as is" and without warranties of any
          kind, express or implied. We disclaim all warranties, including, but
          not limited to, warranties of merchantability, fitness for a
          particular purpose, and non-infringement. We do not warrant that the
          Partner Portal will be uninterrupted, secure, or error-free.
        </p>
        <h2>7. Limitation of Liability</h2>
        <p>
          We shall not be liable for any damages arising out of your use of the
          Partner Portal, including, but not limited to, direct, indirect,
          incidental, consequential, or punitive damages.
        </p>
        <h2>8. Termination</h2>
        <p>
          We may terminate or suspend your account at any time, for any reason,
          without notice. You may terminate your account at any time. Upon
          termination of your account, your right to use the Partner Portal will
          cease immediately.
        </p>
        <h2>9. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of Singapore, without regard to its conflict of law provisions.
        </p>
        <h2>10. Entire Agreement</h2>
        <p>
          These Terms constitute the entire agreement between you and One Date
          regarding your use of the Partner Portal.
        </p>
        <h2>11. Changes to the Terms</h2>
        <p>
          We may update these Terms at any time. You are advised to review the
          Terms periodically for any changes. Your continued use of the Partner
          Portal after the revised Terms are posted constitutes your acceptance
          of the revised Terms.
        </p>
        <h2>12. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at{" "}
          <a style={{ color: "inherit" }} href="mailto:terms@onedate.app">
            terms@onedate.app
          </a>
          .
        </p>
      </div>
    </motion.div>
  );
};

export default PartnerTermsNConditions;
